<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-row>
      <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
        <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

        </v-breadcrumbs>
      </div>
    </v-row><br><br>
    <br />
    <div v-if="init_loading">
      <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
    </div>
    <div v-else>
      <v-card class="card">
        <v-card-title class="heading justify-center">Add Periodicals</v-card-title>
        <v-card-text>
          <v-row class="px-5 pt-4">
            <v-col cols="12" md="3" sm="3">
              <v-text-field v-model="accno" outlined label="Accession No"></v-text-field>
            </v-col>
            <v-col cols="12" md="3" sm="3">
              <v-autocomplete v-model="selectedseries" outlined label="Series" :items="bookSeriesList_periodics"
                item-value="id" item-text="name"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3" sm="3">
              <v-autocomplete v-model="selecteddepartment" outlined label="Department" :items="departmentlist"
                item-text="name" item-value="id"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3" sm="3">
              <v-autocomplete v-model="selectedpublisher" outlined label="Publisher" :items="publisherlist"
                item-value="publisherId" item-text="publisher"></v-autocomplete>
            </v-col>
          </v-row>

          <v-row class="pb-3" justify="center" align="center">
            <v-btn class="m-2" color="info" @click="searchByA_no">Search</v-btn>
          </v-row>


        </v-card-text>
      </v-card>


      <div v-if="loader">
        <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
      </div>
      <div v-else>

        <v-data-table :headers="headers" :items="bookList" class="elevation-1 nowrap" :search="search">
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-text-field v-model="search" label="Search" append-icon="mdi-magnify" single-line hide-details>
              </v-text-field>
              <span style="width: 20px"></span>
              <v-btn class="mb-2 button" @click="addRack">Add Periodicals</v-btn>

            </v-toolbar>
          </template>
          <template v-slot:item.copies="{ item }">
            <span>
              {{ item.copies }}
            </span>

            <v-icon @click="editcopy(item)">mdi mdi-pencil-box</v-icon>

          </template>
          <template v-slot:item.rackInfo="{ item }">
            <span v-if="item.mapLocation == 0">
              <i class="mdi mdi-check check"></i></span>
            <span v-else> <i class="mdi mdi-close cross"></i></span>
            <router-link :to="{ name: 'add-location', params: { bookId: item.id } }" style="text-decoration: none">
              <v-btn small color="info">Add Location</v-btn>
            </router-link>
          </template>
          <template v-slot:item.action="{ item }">
            <div>
              <v-icon @click="editItem(item)">mdi mdi-pencil-box</v-icon>
            </div>
          </template>
          <template v-slot:item.bookItem="{ item }">
            <router-link :to="{ name: 'bookitem_periodicals', params: { bookId: item.id } }"
              style="text-decoration: none">
              <v-btn small color="info">Item</v-btn>
            </router-link>
          </template>
        </v-data-table>
      </div>
    </div>
    <v-dialog v-model="dialog" max-width="1200px">
      <v-card>
        <v-form v-model="valid" ref="form">
          <v-card-title class="heading">
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12" v-if="isImg">
                  <center>
                    <v-img height="150" width="150" :src="img"></v-img>
                  </center>
                </v-col>
                <v-col v-if="!inSave" cols="12" sm="12" md="4" lg="4">
                  <label>Periodical Series</label>
                  <v-autocomplete dense outlined :items="bookSeriesList_periodics" item-value="id" item-text="name"
                    v-model="editedItem.seriesId" persistent-hint></v-autocomplete>
                </v-col>
                <v-col v-else cols="12" sm="12" md="4" lg="4">
                  <label>Periodical Series</label>
                  <v-autocomplete dense outlined :items="bookSeriesList_periodics" item-value="id" item-text="name"
                    v-model="editedItem.seriesId" @change="getavalableacc()" persistent-hint></v-autocomplete>
                </v-col>
                <!-- :disabled="!inSave" -->
                <v-col v-if="editedItem.acc && inSave" cols="12" sm="12" md="4" lg="4">
                  <label>Accesation NO</label><span style="color: red; font-weight: bolder">*</span>
                  <v-text-field dense outlined v-model="editedItem.acc" item-text="roleLink_list_id" item-value="id"
                    persistent-hint readonly disabled :error="errorMsg.acc ? true : false"
                    :error-messages="errorMsg.acc ? 'Required' : ''" :rules="[(v) => !!v || 'required']"
                    @blur="fetchBookInfo()"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4">
                  <label>ISBN</label><span style="color: red; font-weight: bolder">*</span>
                  <v-text-field dense outlined v-model="editedItem.isbn" item-text="roleLink_list_id" item-value="id"
                    persistent-hint :error="errorMsg.isbn ? true : false"
                    :error-messages="errorMsg.isbn ? 'Required' : ''" :rules="[(v) => !!v || 'required']"
                    @blur="fetchBookInfo()"></v-text-field>
                </v-col>
                <v-col style="padding-top:0px;" cols="6" sm="12" md="4" lg="4">
                  <label>Number of Copies</label><span style="color: red; font-weight: bolder">*</span>
                  <v-text-field dense outlined v-model="editedItem.copies" item-text="roleLink_list_id" item-value="id"
                    persistent-hint type="number" :readonly="!inSave" :disabled="!inSave"
                    :error="errorMsg.copies ? true : false" :error-messages="errorMsg.copies ? 'Required' : ''
                      " :rules="[(v) => !!v || 'required']"></v-text-field>
                </v-col>
                <!--<v-col v-if="inSave" cols="12" sm="12" md="4" lg="4">
                            <label v-if="inSave">Date of Entry</label>
                            <v-menu v-if="inSave"
                              v-model="showPicker6"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              full-width
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="editedItem.date_of_entry"
                                  placeholder="Select Date"
                                  hint="YYYY/MM/DD"
                                  persistent-hint
                                  dense
                                  readonly
                                  outlined
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="editedItem.date_of_entry"
                                no-title
                                @input="showPicker6 = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>-->

                <!-- <v-col cols="12" sm="12" md="4" lg="4">
                            <label>Title</label>
                            <v-text-field
                              dense
                              outlined
                              v-model="editedItem.title"
                              item-text="roleLink_list_id"
                              item-value="id"
                              persistent-hint
                            
                            ></v-text-field> 
                          </v-col>-->
                <v-col cols="12" sm="12" md="4" lg="4">
                  <label>Title</label>
                  <v-icon color="blue" class="mr-2" @click="title_dialog = true">mdi-plus-box</v-icon>
                  <v-autocomplete outlined dense :items="allBookInfo" item-text="displayname" item-value="displayname"
                    v-model="selectedtitle" persistent-hint
                    @input.native="searchTitleDropdown($event.srcElement.value)"></v-autocomplete>
                </v-col>
                <v-dialog v-model="title_dialog" width="500">
                  <v-card>

                    <v-card-title class="grey lighten-2">Add Title</v-card-title>
                    <v-card-text style="padding-bottom: 0px">
                      <v-form v-model="valid" ref="addtitlefrom">
                        <label class="add-text">Title</label>
                        <span style="color: red; font-weight: bolder">*</span>
                        <v-text-field class="text" v-model="titlename" outlined dense clearable required></v-text-field>
                        <label class="add-text">Book Classification</label>
                        <span style="color: red; font-weight: bolder">*</span>
                        <v-select :items="bc_list_p" class="text" item-text="name" item-value="id" v-model="selectedbc"
                          outlined dense clearable required></v-select>
                      </v-form>
                    </v-card-text>
                    <div class="text-center">
                      <v-spacer></v-spacer>
                      <v-btn class="m-3" color="error" @click="title_dialog = false">Close</v-btn>
                      <v-btn class="m-3" color="success" @click="addTitle()">Add</v-btn>
                    </div>
                  </v-card>
                </v-dialog>
                <v-col cols="12" sm="12" md="4" lg="4">
                  <label>Description</label>
                  <v-textarea dense outlined rows="2" v-model="editedItem.description" item-text="roleLink_list_id"
                    item-value="id" persistent-hint></v-textarea>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" v-if="!publisherText">
                  <label>Publisher</label>
                  <v-icon color="blue" class="mr-2" @click="publisherdialog = true">mdi-plus-box</v-icon>
                  <v-autocomplete dense outlined :items="publisherList" item-value="publisherId" item-text="publisher"
                    v-model="editedItem.publisherId" persistent-hint></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" v-else>

                  <v-text-field dense outlined v-model="editedItem.publisherId" persistent-hint></v-text-field>
                </v-col>
                <v-dialog v-model="publisherdialog" width="500">
                  <v-card>
                    <v-card-title class="grey lighten-2">
                      Add publisher
                    </v-card-title>

                    <v-card-text style="padding-bottom: 0px">
                      <label class="add-text">Publisher</label><span style="color: red; font-weight: bolder">*</span>
                      <v-text-field class="text" v-model="pubname" outlined dense clearable required></v-text-field>

                      <label class="add-text">Short Name</label><span style="color: red; font-weight: bolder">*</span>
                      <v-text-field class="text" v-model="pubshortname" outlined dense required rows="2"></v-text-field>
                    </v-card-text>

                    <div class="text-center">
                      <v-spacer></v-spacer>
                      <v-btn class="m-3" color="error" @click="publisherdialog = false">
                        Close
                      </v-btn>
                      <v-btn class="m-3" color="success" @click="addpublisher()">Add</v-btn>
                    </div>
                  </v-card>
                </v-dialog>
                <v-col cols="12" sm="12" md="4" lg="4">
                  <label>Place</label>
                  <v-text-field dense outlined v-model="editedItem.place" item-text="roleLink_list_id" item-value="id"
                    persistent-hint></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4">
                  <label>Binding Type</label>
                  <v-autocomplete dense outlined :items="bookFormatList" item-value="id" item-text="name"
                    v-model="editedItem.bookFormat" persistent-hint></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4">
                  <label>Source/vendor</label>
                  <v-text-field dense outlined v-model="editedItem.vendor" item-text="vendor" item-value="vendor"
                    persistent-hint></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" v-if="!book_authors">
                  <label>Author(s)</label>
                  <v-icon color="blue" class="mr-2" @click="authordialog = true">mdi-plus-box</v-icon>
                  <v-autocomplete dense outlined :items="authorList" item-value="authorId" item-text="author"
                    v-model="editedItem.authors" persistent-hint multiple></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" v-else>
                  <label>Author(s)</label>
                  <v-icon color="blue" class="mr-2" @click="authordialog = true">mdi-plus-box</v-icon>
                  <v-text-field dense outlined v-model="editedItem.authors"></v-text-field>
                </v-col>

                <v-dialog v-model="authordialog" width="500">
                  <v-card>
                    <v-card-title class="grey lighten-2">
                      Add Author
                    </v-card-title>

                    <v-card-text style="padding-bottom: 0px">
                      <label class="add-text">Author</label><span style="color: red; font-weight: bolder">*</span>
                      <v-text-field class="text" v-model="authorName" outlined dense clearable required></v-text-field>

                      <label class="add-text">Description</label><span style="color: red; font-weight: bolder">*</span>
                      <v-textarea class="text" v-model="author_description" outlined dense required rows="2"></v-textarea>
                    </v-card-text>

                    <div class="text-center">
                      <v-spacer></v-spacer>
                      <v-btn class="m-3" color="error" @click="authordialog = false">
                        Close
                      </v-btn>
                      <v-btn class="m-3" color="success" @click="addAuthor()">Add</v-btn>
                    </div>
                  </v-card>
                </v-dialog>

                <v-col cols="12" sm="12" md="4" lg="4">
                  <label>Language</label>
                  <v-autocomplete dense outlined :items="languageList" item-value="languageId" item-text="language"
                    v-model="editedItem.languageId" persistent-hint></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4">
                  <label>Keywords</label>
                  <v-text-field dense outlined v-model="editedItem.keywords" item-text="roleLink_list_id" item-value="id"
                    persistent-hint></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4">
                  <label>Subject</label>
                  <v-text-field dense outlined v-model="editedItem.subject" item-text="roleLink_list_id" item-value="id"
                    persistent-hint></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4">
                  <label>Frequency</label>
                  <v-select dense outlined :items="frequency_list" item-value="id" item-text="name"
                    v-model="editedItem.frequency" persistent-hint></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4">
                  <br />
                  <v-checkbox dense outlined label="Show Opac" v-model="editedItem.show_opac"
                    persistent-hint></v-checkbox>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4">
                  <label>Department</label>
                  <v-autocomplete class="text" item-text="name" item-value="id" :items="libdeptlist"
                    v-model="editedItem.libdept" dense outlined></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" v-if="!editedItem.isDonated">
                  <label>Date of Purchase</label>
                  <v-menu v-model="showPicker1" :close-on-content-click="false" transition="scale-transition" offset-y
                    full-width max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field v-model="editedItem.purchaseDate" placeholder="Select Date" hint="YYYY/MM/DD"
                        persistent-hint dense readonly outlined v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="editedItem.purchaseDate" no-title
                      @input="showPicker1 = false"></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" sm="12" md="4" lg="4">
                  <label>Subscription Periodic From</label>
                  <v-menu v-model="picker1" :close-on-content-click="false" transition="scale-transition" offset-y
                    full-width max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field v-model="editedItem.subscription_from" placeholder="Select Date" hint="YYYY/MM/DD"
                        persistent-hint dense readonly outlined v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="editedItem.subscription_from" no-title
                      @input="picker1 = false"></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" sm="12" md="4" lg="4">
                  <label>Subscription Periodic To</label>
                  <v-menu v-model="picker2" :close-on-content-click="false" transition="scale-transition" offset-y
                    full-width max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field v-model="editedItem.subscription_to" placeholder="Select Date" hint="YYYY/MM/DD"
                        persistent-hint dense readonly outlined v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="editedItem.subscription_to" no-title @input="picker2 = false"></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" sm="12" md="4" lg="4">
                  <label>Volume Periodic From</label>

                  <v-text-field v-model="editedItem.volume_from" persistent-hint dense outlined></v-text-field>

                </v-col>

                <v-col cols="12" sm="12" md="4" lg="4">
                  <label>Volume Periodic To</label>

                  <v-text-field v-model="editedItem.volume_to" dense outlined></v-text-field>

                </v-col>

                <v-col cols="12" sm="12" md="4" lg="4">
                  <label>Issue From</label>

                  <v-text-field v-model="editedItem.issue_from" dense outlined></v-text-field>

                </v-col>

                <v-col cols="12" sm="12" md="4" lg="4">
                  <label>issue To</label>

                  <v-text-field v-model="editedItem.issue_to" dense outlined></v-text-field>

                </v-col>

                <!---pub year-->
                <!--<v-col cols="12" sm="12" md="4" lg="4">
                            <label>Publication Year</label>
                           <v-menu
                              v-model="showPickerYP"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              full-width
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="editedItem.publicationYear"
                                  placeholder="Select Year"
                                  hint="YYYY"
                                  persistent-hint
                                  dense
                                  outlined
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="editedItem.publicationYear"
                                no-title
                                type="year"
                                @input="showPickerYP = false"
                              ></v-date-picker>
                
                            </v-menu>
                          
                          </v-col>
                          
                           <v-col cols="12" sm="12" md="4" lg="4">
                            <label>Volume</label>
                            <v-text-field
                              dense
                              outlined
                              v-model="editedItem.volume"
                              item-text="roleLink_list_id"
                              item-value="id"
                              persistent-hint
                              type="number"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="4" lg="4">
                            <label>Edition</label>
                            <v-text-field
                              dense
                              outlined
                              v-model="editedItem.edition"
                              item-text="roleLink_list_id"
                              item-value="id"
                              persistent-hint
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="4" lg="4">
                            <label>Edition Year</label>
                            <v-text-field
                              dense
                              outlined
                              v-model="editedItem.year"
                              item-text="roleLink_list_id"
                              item-value="id"
                              persistent-hint
                              type="number"
                            ></v-text-field>
                          </v-col>-->
                <v-col cols="12" sm="12" md="4" lg="4">
                  <label>Number of Pages</label>
                  <v-text-field dense outlined type="number" v-model="editedItem.pages" item-text="roleLink_list_id"
                    item-value="id" persistent-hint></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4">
                  <label>Classification/Call No</label>
                  <v-text-field dense outlined v-model="editedItem.cno" item-text="roleLink_list_id" item-value="id"
                    persistent-hint></v-text-field>
                </v-col>
                <!--<v-col cols="12" sm="12" md="4" lg="4">
                            <label>Book Type</label>
                            <v-autocomplete
                              dense
                              outlined
                              :items="bookTypeList"
                              item-value="bookTypeId"
                              item-text="bookType"
                              v-model="editedItem.bookTypeId"
                              persistent-hint
                            ></v-autocomplete>
                          </v-col>
                           
                           
                        
                          
                            <v-col  cols="12" sm="12" md="4" lg="4">
                              <label v-if="!editedItem.isDonated">Bill Number</label>
                              <v-text-field v-if="!editedItem.isDonated"
                                dense
                                outlined
                                v-model="editedItem.billNo"
                                item-text="roleLink_list_id"
                                item-value="id"
                                persistent-hint
                                
                              ></v-text-field>
                            </v-col>
                             -->
                <v-col cols="12" sm="12" md="4" lg="4">
                  <label>Purchase price</label>
                  <v-text-field dense outlined v-model="editedItem.price" item-text="roleLink_list_id" item-value="id"
                    persistent-hint type="number" @input="cal_discount()"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4">
                  <label>Discount</label>
                  <v-text-field dense outlined v-model="editedItem.discount" item-text="roleLink_list_id" item-value="id"
                    persistent-hint type="number" @input="cal_discount()"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4">
                  <label>Net Price</label>
                  <v-text-field dense outlined v-model="editedItem.bookprice" item-text="roleLink_list_id" item-value="id"
                    persistent-hint type="number"></v-text-field>
                </v-col>
                <!-- <v-col cols="6" sm="12" md="4" lg="4">
                              <label>Discount Percentage</label>
                              <v-text-field
                                dense
                                outlined
                                v-model="editedItem.discountPrice"
                                item-text="roleLink_list_id"
                                item-value="id"
                                persistent-hint
                                type="number"
                              ></v-text-field>
                            </v-col> -->

                <!-- 
                           <v-col cols="12" sm="12" md="4" lg="4">
                            <label>Program</label>
                            <v-autocomplete
                              class="text"
                              item-text="name"
                              item-value="id"
                              :items="program_list"
                              v-model="editedItem.program"
                              dense
                              outlined
                            ></v-autocomplete>
                          </v-col>
                         
                         <v-col cols="12" sm="12" md="4" lg="4">
                            <label>Date of Publication</label>
                            <v-menu
                              v-model="showPicker"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              full-width
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="editedItem.publicationDate"
                                  placeholder="Select Date"
                                  hint="YYYY/MM/DD"
                                  persistent-hint
                                  dense
                                  readonly
                                  outlined
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="editedItem.publicationDate"
                                no-title
                                @input="showPicker = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          
                         
                          <v-col cols="12" sm="12" md="4" lg="4">
                            <label>Book Category</label>
                            <v-autocomplete
                              dense
                              outlined
                              :items="bookCatList"
                              item-value="bookCatId"
                              item-text="bookCat"
                              v-model="editedItem.bookCatId"
                              persistent-hint
                            ></v-autocomplete>
                          </v-col>-->


                <v-col cols="12" sm="12" md="4" lg="4">
                  <label>Library</label><span style="color: red; font-weight: bolder">*</span>
                  <!--  <v-text-field
                              v-model="editedItem.library"
                              
                              filled
                              readonly
                            ></v-text-field>-->
                  <v-autocomplete dense outlined :items="libraryList" item-value="id" item-text="name"
                    v-model="editedItem.library" persistent-hint :error="errorMsg.library ? true : false" :error-messages="errorMsg.library ? 'Required' : ''
                      " :rules="[(v) => !!v || 'required']"></v-autocomplete>
                </v-col>

                <!--  <v-col cols="12" sm="12" md="4" lg="4">
                              <v-checkbox
                                style="padding-top: 30px"
                                v-model="editedItem.isDonated"
                                label="Is Received From Donation?"
                                color="black"
                                dense
                                hide-details
                              ></v-checkbox>
                            </v-col>-->


              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="button-cancel" text @click="close">Cancel</v-btn>
            <v-btn class="button" @click="save">Save</v-btn>
          </v-card-actions>
          <!-- :disabled="!valid" -->
        </v-form>
      </v-card>
    </v-dialog>

    <template>
      <div class="text-center">
        <v-dialog v-model="newBook" width="800">
          <v-card>
            <v-card-title class="headline grey lighten-2">
              Newly added book copies data
            </v-card-title>

            <v-card-text>
              <v-simple-table v-if="newbook_data.length > 0" class="mt-3" style="border: 1px solid">
                <template v-slot:default>
                  <thead>
                    <tr style="background: lightgray">
                      <th class="text-left" style="font-size: 16px">
                        Book Title
                      </th>
                      <th class="text-left" style="font-size: 16px">
                        Accession Number
                      </th>
                      <th class="text-left" style="font-size: 16px">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item1 in newbook_data" :key="item1.acc_no">
                      <td>{{ item1.title }}</td>
                      <td>{{ item1.acc_no }}</td>
                      <td>{{ item1.status }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="info" @click="newBook = false"> OK </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>
  </div>
</template>
   </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard

export default {
  data: () => ({
    showPicker: false,
    showPicker1: false,
    showPicker5: false,
    showPicker6: false,
    bookFormatList: [],
    items: [
      {
        text: 'Dashboard',
        disabled: false,
        href: '/home',
      },
      {
        text: localStorage.getItem("jhhjbyj"),
        disabled: false,
        href: '/sublink',
      },
      {
        text: 'Add Book & periodicals',
        disabled: false,
        href: '/add_book_periodicals',
      },

      {
        text: 'Add Periodicals',
        disabled: true,
        href: '',
      },
    ],
    bookTypeList: [],
    BookCatList: [],
    bookSeriesList: [],
    accno: "",
    languageList: [],
    publisherList: [],
    libraryList: "",
    bookList: [],
    authorList: [],
    valid: false,
    init_loading: false,
    loader: false,
    errorMsg: {
      roleLinkId: false,
    },
    snackbar_msg: "",
    color: "",
    menu1: false,
    menu2: false,
    snackbar: false,
    search: "",
    org: "",
    inSave: true,
    newBook: false,
    program_list: null,
    program: null,
    dialog: false,
    authordialog: false,
    authorName: "",
    author_description: "",
    headers: [
      {
        text: "Sr no",
        align: "left",
        sortable: true,
        value: "srno",
      },
      { text: "Actions", value: "action", sortable: false },
      { text: "Book Item(s)", value: "bookItem", sortable: false },
      {
        text: "ISBN",
        align: "left",
        sortable: true,
        value: "isbn",
      },
      {
        text: "Title",
        align: "left",
        sortable: true,
        value: "title",
      },
      {
        text: "Edition",
        align: "left",
        sortable: true,
        value: "edition",
      },
      {
        text: "date_of_entry",
        align: "left",
        sortable: true,
        value: "date_of_entry",
      },
      {
        text: "Binding Type",
        align: "left",
        sortable: true,
        value: "bookFormat",
      },
      {
        text: "Book Type",
        align: "left",
        sortable: true,
        value: "bookType",
      },
      {
        text: "Program",
        align: "left",
        sortable: true,
        value: "program_name",
      },
      {
        text: "Book Category",
        align: "left",
        sortable: true,
        value: "bookCat",
      },
      {
        text: "Publisher",
        align: "left",
        sortable: true,
        value: "publisher",
      },
      {
        text: "Source/Vendor",
        align: "left",
        sortable: true,
        value: "vendor",
      },
      {
        text: "No of Copies",
        align: "left",
        sortable: true,
        value: "copies",
      },
      { text: "Rack Info Added?", value: "rackInfo", sortable: false },


    ],
    bookCategoryList: [],
    roleLink_list: [],
    role_list: [],
    editedIndex: -1,
    editedItem: {
      subscription_to: "",
      subscription_from: "",
      volume_from: "",
      volume_to: "",
      issue_from: "",
      issue_to: "",
      show_opac: true,
      frequency: "",
      place: "",
      cno: "",
      libdept: "",
      acc: "",
      w_date: "",
      w_no: "",
      p_place: "",
      seriesId: "",
      program: "",
      vendor: "",
      isbn: "",
      title: "",
      description: "",
      authors: null,
      edition: "",
      keywords: "",
      subject: "",
      place: "",
      volume: "",
      year: "",
      pages: null,
      copies: null,
      price: 0,
      bookprice: 0,
      discount: 0,
      billNo: "",
      authorId: null,
      isDonated: "",
      language: null,
      purchaseDate: null,
      publicationDate: null,
      publicationYear: null,
      bookFormat: null,
      bookType: null,
      bookCat: null,
      publisher: null,
      library: null,
      isactive: false,
      id: "",
      authorText: false,
      publisherText: null,
      date_of_entry: null,
      perioic_or_book: "periodic",
    },
    publisherText: false,
    defaultItem: {
      perioic_or_book: "periodic",
      subscription_to: "",
      subscription_from: "",
      volume_from: "",
      volume_to: "",
      issue_from: "",
      issue_to: "",
      show_opac: true,
      frequency: '',
      place: "",
      seriesId: "",
      program: "",
      isbn: "",
      title: "",
      description: "",
      authors: null,
      edition: "",
      keywords: "",
      subject: "",
      place: "",
      volume: "",
      year: "",
      pages: null,
      copies: null,
      price: 0,
      bookprice: 0,
      discountPrice: 0,
      billNo: "",
      isDonated: "",
      authorId: null,
      purchaseDate: null,
      publicationDate: null,
      publicationYear: null,
      bookFormat: null,
      bookType: null,
      bookCat: null,
      publisher: null,
      library: null,
      language: null,
      isactive: false,
      id: "",
      date_of_entry: null
    },
    book_authors: true,
    img: null,
    isImg: false,
    newbook_data: [],
    libdeptlist: [],
    frequency_list: [],
    rackData: [
      {
        rackName: "R1",
        blockName: "B1",
        category: "Comp",
        capacity: 5,
        available: 5,
      },
      {
        rackName: "R1",
        blockName: "B2",
        category: "Comp",
        capacity: 3,
        available: 3,
      },
      {
        rackName: "R1",
        blockName: "B3",
        category: "Comp",
        capacity: 2,
        available: 2,
      },
      {
        rackName: "R2",
        blockName: "B1",
        category: "mech",
        capacity: 5,
        available: 5,
      },
    ],
    bookCopies: 2,
    remaning: 2,
    publisherlist: [],
    departmentlist: [],
    autherlist: [],
    titlelist: [],
    isbnlist: [],
    serieslist: [],
    selectedpublisher: "ALL",
    selecteddepartment: "ALL",
    selectedauther: "ALL",
    selectedtitle: "ALL",
    selectedisbn: "ALL",
    selectedseries: "ALL",
    editcopydialog: true,
    picker1: "",
    picker2: "",
    pubname: "",
    pubshortname: "",
    publisherdialog: false,
    bookSeriesList_periodics: [], titlename: "",
    title_dialog: false,
    allBookInfo: [],
    bc_list_p: [],
    selectedbc: "",
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Periodicals" : "Edit Periodicals";
    },
  },


  mounted() {
    this.onLoad();
    this.editedItem.date_of_entry = new Date().toISOString().slice(0, 10)
  },
  watch: {
    dialog() {
      this.img = null;
      this.isImg = false;
      this.book_authors = false;
      // this.editedItem.isbn = ''
      this.publisherText = false;
    },
  },
  methods: {
    addTitle() {
      this.titlename = this.titlename.trim();
      const data = {
        titlename: this.titlename,
        bc: this.selectedbc,
      };
      if (this.titlename && this.titlename != "" && this.selectedbc && this.selectedbc != "") {
        axios
          .post("/admin/addTitlePeriodical", data)
          .then((res) => {
            if (res.data.msg == "success") {
              this.searchTitleDropdown(this.titlename)
              this.titlename = "";
              this.selectedbc = "";
              this.title_dialog = false;
              this.showSnackbar("green", "Title Added Successfully.");
            } else {
              this.showSnackbar("#b71c1c", res.data.msg);
            }
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", "Something went wrong!!!");
            console.log(error);
          })
          .finally(() => { });
      } else {
        this.showSnackbar("#b71c1c", "Please enter required fields");
        this.$refs.addtitlefrom.validate();
      }
    },
    searchTitleDropdown(value) {
      if (value != "") {
        var params = { text: value, seaechbyacc: "title" };
        axios
          .post("/IssueBook/getsearchbytitleperiodicalOnly", params)
          .then((res) => {
            if (res.data.msg == "200") {
              this.allBookInfo = res.data.allBookInfo;
              console.log(this.allBookInfo);
            }
          })
      }
    },
    editcopy(item) {
      this.inSave = false;
      this.editcopydialog = true;
    },
    searchByA_no() {
      var params = {
        selecteddepartment: this.selecteddepartment,
        selectedpublisher: this.selectedpublisher,
        selectedseries: this.selectedseries,
        selectedbooktype: 'ALL',
        Acc_no: this.accno
      };

      this.loader = true;
      axios
        .post("/Librarian/getBookDataWithfilter", params)
        .then((res) => {
          if (res.data.msg == "200") {
            this.loader = false;
            this.bookList = res.data.book_list;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
        })
        .finally(() => {
          // var overlay = false;
        });
    },
    fetchBookInfo() {
      if (this.editedItem.isbn !== "") {
        axios
          .get(
            "https://cors-anywhere.herokuapp.com/https://www.googleapis.com/books/v1/volumes?q=" +
            this.editedItem.isbn
          )
          .then((res) => {
            this.editedItem.title = res.data.items[0].volumeInfo.title;
            this.editedItem.description =
              res.data.items[0].volumeInfo.description;
            this.editedItem.pages = res.data.items[0].volumeInfo.pageCount;
            this.editedItem.publicationDate =
              res.data.items[0].volumeInfo.publishedDate;
            let auth = res.data.items[0].volumeInfo.authors;
            if (auth.length > 0) {
              this.book_authors = true;
              for (let i = 0; i < auth.length; i++) {
                if (this.editedItem.authors == null) {
                  this.editedItem.authors = auth[i];
                } else {
                  this.editedItem.authors =
                    this.editedItem.authors + "," + auth[i];
                }
              }
              this.editedItem.authorText = true;
            } else {
              this.book_authors = false;
              this.editedItem.authorText = false;
            }

            if (res.data.items[0].volumeInfo.publisher !== null) {
              this.publisherText = true;
              this.editedItem.publisherText = this.publisherText;
              this.editedItem.publisherId =
                res.data.items[0].volumeInfo.publisher;
            } else {
              this.publisherText = false;
              this.editedItem.publisherText = this.publisherText;
            }
            this.img = res.data.items[0].volumeInfo.imageLinks.smallThumbnail;
            if (this.img) {
              this.isImg = true;
            } else {
              this.isImg = false;
            }
          });
      } else {
        this.editedItem = {};
      }
    },
    // cal_discount(pprice, price) {
    //   var cost = parseInt(pprice);
    //   var amt = parseInt(price);
    //   var discount = ((cost - amt) / cost) * 100;
    //   var per = discount.toFixed(0);
    //   this.editedItem.bookprice = per;
    // },
    cal_discount() {
      if (this.editedItem.discount == 0) {
        this.editedItem.bookprice = this.editedItem.price;
      } else {
        var discount = (this.editedItem.price * this.editedItem.discount) / 100;
        this.editedItem.bookprice = (this.editedItem.price - discount);
      }

    },
    addRack() {
      this.inSave = true;
      this.dialog = true;
      this.editedIndex = -1;
      // this.$refs.form.reset();
    },
    editItem(item) {
      console.log("item---", item.isbn);
      this.inSave = false;
      this.editedIndex = this.bookList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      var name = item.role;
      for (let x in this.role_list) {
        // alert(this.role_list[x].name+" : "+name)
        if (this.role_list[x].name == name) {
          this.editedItem.role = this.role_list[x].name;
          this.editedItem.roleId = this.role_list[x].id;
          // this.item.role = this.role_list[x].name
          break;
        }
      }
    },
    getavalableacc() {
      var params = { seriesid: this.editedItem.seriesId };
      this.loader = true;
      axios.post("/Librarian/getnewacc_no_track", params).then((res) => {
        if (res.data.msg == "200") {
          this.editedItem.acc = res.data.acc_no;
          this.editedItem.isbn = res.data.isbn;
          this.loader = false;
        } else {
          this.loader = false;
        }
      });
    },
    onLoad() {
      this.init_loading = true;
      axios
        .post("/Librarian/getBookData")
        .then((res) => {
          if (res.data.msg == "200") {
            this.init_loading = false;
            // this.bookCategoryList = res.data.bookCategory_list
            // this.roleLink_list = res.data.rack_list;
            // this.role_list = res.data.role_list;
            // this.org = res.data.org;
            this.program_list = res.data.programList;
            this.libdeptlist = res.data.librarydeptlist;
            this.bookList = res.data.book_list;
            this.bookTypeList = res.data.bookTypeList;
            this.bookFormatList = res.data.bookFormatList;
            this.bookCatList = res.data.bookCatList;
            this.languageList = res.data.languageList;
            this.publisherList = res.data.publisherList;
            this.libraryList = res.data.lib_list;
            this.authorList = res.data.authorList;
            this.bookSeriesList = res.data.bookSeriesList;
            this.bookSeriesList_periodics = res.data.bookSeriesList_periodics;

            this.publisherlist = res.data.publisherList;
            this.departmentlist = res.data.librarydeptlist;
            this.autherlist = res.data.authorList;

            this.serieslist = res.data.bookSeriesList;
            this.editedItem.library = res.data.libid.id;
            this.frequency_list = res.data.frequency_list;
            this.bc_list_p = res.data.bc_list;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", error); // show snackbar
          window.console.log(error);
        })
        .finally(() => {
          // var init_loading = false;
        });
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    close() {
      this.dialog = false;
      // ==this.$refs.form.reset()
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {

      this.editedItem.title = this.selectedtitle;

      if (this.editedItem.isbn == null || this.editedItem.isbn == "") {
        this.showSnackbar("#b71c1c", "Please Enter ESBN !!!");
      } else if (
        this.editedItem.copies == null ||
        this.editedItem.copies == ""
      ) {
        this.showSnackbar("#b71c1c", "Please Enter Number of Copies !!!");
      } else if (
        this.editedItem.library == null ||
        this.editedItem.library == ""
      ) {
        this.showSnackbar("#b71c1c", "Please Select Library !!!");
      } else {
        this.editedItem.copies = parseInt(this.editedItem.copies);
        this.editedItem.pages = parseInt(this.editedItem.pages);
        // this.editedItem.price = toString(this.editedItem.price)
        this.editedItem.purchaseDate = moment(
          String(this.editedItem.purchaseDate)
        ).format("DD-MM-YYYY");
        this.editedItem.publicationDate = moment(
          String(this.editedItem.publicationDate)
        ).format("DD-MM-YYYY");
        if (this.editedIndex > -1) {
          if (this.$refs.form.validate()) {
            axios
              .post("/Librarian/editBook", this.editedItem)
              .then((res) => {
                if (res.data.msg == "success") {
                  //window.alert(res.data.msg)
                  // Object.assign(this.roleLink_list[this.editedIndex], this.editedItem);
                  this.showSnackbar("#4caf50", "Book Updated Successfully...");
                  this.searchByA_no(); // show snackbar on success
                  if (res.data.isbnExistMsg) {
                    this.showSnackbar("#b71c1c", res.data.isbnExistMsg); // show snackbar on error
                  }
                  this.close();
                } else {
                  this.showSnackbar("#b71c1c", res.data.msg); // show snackbar on error
                }
              })
              .catch((error) => {
                window.console.log(error);
                this.init_loading = false;

              });

          }
        } else {
          this.init_loading = true;
          axios
            .post("/Librarian/saveBook", this.editedItem)
            .then((res) => {
              if (res.data.msg == "success") {
                this.init_loading = false;
                this.newbook_data = res.data.book_data;
                this.newBook = true;
                this.showSnackbar("#4caf50", "Book Added Successfully!!!"); // show snackbar on success
                this.close();
              } else {
                this.init_loading = false;
                this.showSnackbar("#b71c1c", res.data.msg);
              }
            })
            .catch((error) => {
              window.console.log(error);
              this.init_loading = true;
              this.close();
            });

        }
      }
    }, //save()

    activeOn(item) {
      axios
        .post("/admin/rackIsactive", item)
        .then(() => {
          // this.sec_mode_list = res.data.user_list;
          this.showSnackbar("#4CAF50", "Rack updated successfully...");
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong!!!");
          console.log(error);
        })
        .finally(() => {
          this.edit_btn_load = false;
          axios;
        });
    },

    isValidated() {
      if (this.editedItem.roleLinkId) {
        return true;
      } else {
        if (!this.editedItem.roleLinkId) {
          this.errorMsg.roleLinkId = true;
        }

        return false;
      }
    },

    addAuthor() {
      if (this.authorName && this.author_description) {
        this.authorName = this.authorName.trim();
        this.author_description = this.author_description.trim();
      }
      const data = {
        author_name: this.authorName,
        author_des: this.author_description,
      };
      if (this.authorName && this.author_description) {
        axios
          .post("/admin/addAuthor", data)
          .then((res) => {
            this.authorList = res.data.author_list;
            if (res.data.msg == "success") {
              this.$refs.form.reset();
              this.authordialog = false;
              this.showSnackbar("green", "Auther Added Successfully.");
            } else {
              this.showSnackbar("#b71c1c", res.data.msg);
            }
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", "Something went wrong!!!");
            console.log(error);
          })
          .finally(() => { });
      } else {
        this.showSnackbar("#b71c1c", "Please enter required fields");
        this.$refs.form.validate();
      }
    },
    addpublisher() {
      if (this.authorName && this.author_description) {
        this.authorName = this.authorName.trim();
        this.author_description = this.author_description.trim();
      }
      const data = {
        publisher_name: this.pubname,
        publisherShortName: this.pubshortname,
        publisherDisplayOrder: 0,
      };
      if (this.pubshortname && this.pubname) {
        axios
          .post("/admin/addPublisher", data)
          .then((res) => {
            this.publisherList = res.data.publisher_list;
            if (res.data.msg == "success") {
              this.$refs.form.reset();
              this.publisherdialog = false;
              this.showSnackbar("green", "Publiasher Added Successfully.");
            } else {
              this.showSnackbar("#b71c1c", res.data.msg);
            }
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", "Something went wrong!!!");
            console.log(error);
          })
          .finally(() => { });
      } else {
        this.showSnackbar("#b71c1c", "Please enter required fields");
        this.$refs.form.validate();
      }
    },
  },

};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.button :hover {
  color: white;
}

.v-icon-size {
  font-size: 20px;
}

.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}

.edit-avatar:hover {
  background-color: #b0bec5;
}

.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}

.edit-v-icon:hover {
  color: white;
}

.fields {
  padding: 0px !important;
}

.isdonated {
  margin-top: 15px;
  padding-left: 15px !important;
}

.check {
  color: green;
  font-size: 20px;
  padding-right: 10px;
}

.cross {
  color: red;
  font-size: 20px;
  padding-right: 5px;
}

.col-sm-12.col-md-4.col-lg-4.col-12 {
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>